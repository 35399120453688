import React from "react";

const config = {
  ROMS: {
    owlia: {
      name: "The Legends of Owlia",
      description: (
        <span>
          <a
            href="http://www.gradualgames.com/p/the-legends-of-owlia_1.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Owlia by Gradual Games
          </a>{" "}
          /{" "}
          <a
            href="http://www.infiniteneslives.com/owlia.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy the cartridge
          </a>
        </span>
      ),
      url: "https://cdn.jsdelivr.net/gh/bfirsh/jsnes-roms@master/owlia.nes"
    },
    nomolos: {
      name: "Nomolos: Storming the Catsle",
      description: (
        <span>
          <a
            href="http://www.gradualgames.com/p/nomolos-storming-catsle.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Monolos by Gradual Games
          </a>{" "}
          /{" "}
          <a
            href="http://www.infiniteneslives.com/nomolos.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy the cartridge
          </a>
        </span>
      ),
      url: "https://cdn.jsdelivr.net/gh/bfirsh/jsnes-roms@master/nomolos.nes"
    },
    croom: {
      name: "Concentration Room",
      description: (
        <span>
          <a
            href="http://www.pineight.com/croom/README"
            target="_blank"
            rel="noopener noreferrer"
          >
            Concentration Room
          </a>{" "}
          by Damian Yerrick
        </span>
      ),
      url:
        "https://cdn.jsdelivr.net/gh/bfirsh/jsnes-roms@master/croom/croom.nes"
    },
    lj65: {
      name: "LJ65",
      description: (
        <span>
          <a
            href="http://harddrop.com/wiki/LJ65"
            target="_blank"
            rel="noopener noreferrer"
          >
            Concentration Room
          </a>{" "}
          by Damian Yerrick
        </span>
      ),
      url: "https://cdn.jsdelivr.net/gh/bfirsh/jsnes-roms@master/lj65/lj65.nes"
    },
MarioBros: {
      name: "Mario Bros",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/MarioBros/MarioBros.nes"
    },
SuperMarioBros: {
      name: "Super Mario Bros",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/SuperMarioBros/SuperMarioBros.nes"
    },
Asterix: {
      name: "Asterix",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Asterix/Asterix.nes"
    },
Castelvania: {
      name: "Castelvania",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Castelvania/Castelvania.nes"
    },
ChampionshipRally: {
      name: "Championship Rally",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/ChampionshipRally/ChampionshipRally.nes"
    },
DonkeyKongClassics: {
      name: "Donkey Kong Classics",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/DonkeyKongClassics/DonkeyKongClassics.nes"
    },
DoubleDragon: {
      name: "Double Dragon",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/DoubleDragon/DoubleDragon.nes"
    },
DoubleDragonIITheRevenge: {
      name: "Double Dragon II - The Revenge",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/DoubleDragonII-TheRevenge/DoubleDragonII-TheRevenge.nes"
    },
DragonballLeSecretduDragon: {
      name: "Dragonball - Le Secret du Dragon",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Dragonball-LeSecretduDragon/Dragonball-LeSecretduDragon.nes"
    },
FerrariGrandPrixChallenge: {
      name: "Ferrari Grand Prix Challenge",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/FerrariGrandPrixChallenge/FerrariGrandPrixChallenge.nes"
    },
GhostbustersII: {
      name: "Ghostbusters II",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/GhostbustersII/GhostbustersII.nes"
    },
Gremlins2TheNewBatch: {
      name: "Gremlins 2 - The New Batch",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Gremlins2-TheNewBatch/Gremlins2-TheNewBatch.nes"
    },
HomeAlone: {
      name: "Home Alone",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/HomeAlone/HomeAlone.nes"
    },
HomeAlone2LostinNewYork: {
      name: "Home Alone 2 - Lost in New York",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/HomeAlone2-LostinNewYork/HomeAlone2-LostinNewYork.nes"
    },
Hook: {
      name: "Hook",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Hook/Hook.nes"
    },
IndianaJonesandtheLastCrusade: {
      name: "Indiana Jones and the Last Crusade",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/IndianaJonesandtheLastCrusade/IndianaJonesandtheLastCrusade.nes"
    },
KirbysAdventure: {
      name: "Kirby's Adventure",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/KirbysAdventure/KirbysAdventure.nes"
    },
LethalWeapon: {
      name: "Lethal Weapon",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/LethalWeapon/LethalWeapon.nes"
    },
Metroid: {
      name: "Metroid",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Metroid/Metroid.nes"
    },
Monopoly: {
      name: "Monopoly",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Monopoly/Monopoly.nes"
    },
PacMan: {
      name: "Pac-Man",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Pac-Man/Pac-Man.nes"
    },
Paperboy: {
      name: "Paperboy",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Paperboy/Paperboy.nes"
    },
RoboCop: {
      name: "RoboCop",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/RoboCop/RoboCop.nes"
    },
StarForce: {
      name: "Star Force",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/StarForce/StarForce.nes"
    },
StarWars: {
      name: "Star Wars",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/StarWars/StarWars.nes"
    },
SuperMarioBros3: {
      name: "Super Mario Bros. 3",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/SuperMarioBros3/SuperMarioBros3.nes"
    },
TerminatorII: {
      name: "Terminator 2 : Le Jugement Dernier",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/TerminatorII/TerminatorII.nes"
    },
Tetris: {
      name: "Tetris",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Tetris/Tetris.nes"
    },
TheFlintstonesTheRescueofDinoHoppy: {
      name: "The Flintstones - The Rescue of Dino & Hoppy",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/TheFlintstones-TheRescueofDino&Hoppy/TheFlintstones-TheRescueofDino&Hoppy.nes"
    },
TheLegendofZelda: {
      name: "The Legend of Zelda",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/TheLegendofZelda/TheLegendofZelda.nes"
    },
TinyToonAdventures2TroubleinWackyland: {
      name: "Tiny Toon Adventures 2 - Trouble in Wackyland",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/TinyToonAdventures2-TroubleinWackyland/TinyToonAdventures2-TroubleinWackyland.nes"
    },
Willow: {
      name: "Willow",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Willow/Willow.nes"
    },
TomJerryTheUltimateGameofCatandMouse: {
      name: "Tom & Jerry - The Ultimate Game of Cat and Mouse !",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Tom&Jerry-TheUltimateGameofCatandMouse/Tom&Jerry-TheUltimateGameofCatandMouse.nes"
    },
ZeldaII: {
      name: "Zelda 2 : The Adventure of Link",
      description: (
        <span>
          by GuiYom87
        </span>
      ),
      url: "http://www.ghr36cloud.fr:88/roms/Zelda/Zelda-2-The-Adventure-of-Link.nes"
    }
  },
  GOOGLE_ANALYTICS_CODE: process.env.REACT_APP_GOOGLE_ANALYTICS_CODE,
  SENTRY_URI: process.env.REACT_APP_SENTRY_URI
};

export default config;
